import { React, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
const URL = process.env.REACT_APP_API_URL;

const Instrument=[
  "Piano",
  "Guitar",
  "Singing",
  "Battery",
  "Violin", 
  "Alto",
  "Cello",
  "Bass",
  "Transverse flute",
  "Oboe",
  "Clarinet",
  "Bassoon",
  "French horn",
  "Trumpet",
  "Trombone",
  "Tuba",
  "Percussion",
  'Other'
  ]

export default function Signup() {
  const INITIAL_STATE = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    professionalMusician: "",
    instrument: "",
  };
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const [data, setData] = useState(INITIAL_STATE);
  const [showpassword, setShowPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmPassword] = useState(false);

  const validate = (value) => {
    let error = {};
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = /^(\+33|0)[1-9](\d{2}){4}$/;
    const phoneNumber1 = value.phone;
    const phoneNumber2 = value.phone;

    if (!value.firstName.trim()) {
      error.firstName = "Le prénom est requis";
    }
    if (!value.lastName.trim()) {
      error.lastName = "Le nom de famille est requis";
    }
    if (!value.email.trim()) {
      error.email = "L'adresse e-mail est requise";
    } else if (!regex.test(value.email)) {
      error.email = "Veuillez saisir une adresse e-mail valide";
    }
    if (!value.phone.trim()) {
      error.phone = "Le numéro de contact est requis";
    } else if (!phoneRegex.test(phoneNumber1)) {
      error.phone = "Numéro invalide";
    } else if (!phoneRegex.test(phoneNumber2)) {
      error.phone = "Numéro invalide";
    }

    if (!value.professionalMusician.trim()) {
      error.professionalMusician = "Musicien professionnel est requis";
    }
    if (!value.instrument.trim()) {
      error.instrument = "Instrument est requis";
    }

    if (!value.password.trim()) {
      error.password = "Le mot de passe est requis";
    } else if (value.password.trim().length < 8) {
      error.password = "Le mot de passe doit comporter 8 caractères ou plus";
    } else if (!/\d/.test(value.password)) {
      error.password = "Le mot de passe doit contenir au moins 1 chiffre";
    } else if (!/[!@#$%&?]/.test(value.password)) {
      error.password =
        "Le mot de passe doit contenir au moins 1 caractère spécial";
    } else if (!/[A-Z]/.test(value.password)) {
      error.password =
        "Le mot de passe doit contenir au moins 1 lettre majuscule";
    }

    if (!value.confirmPassword.trim()) {
      error.confirmPassword = "La confirmation du mot de passe est requise";
    } else if (value.confirmPassword !== value.password) {
      error.confirmPassword = "Les mots de passe ne correspondent pas";
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
    const validationErrors = validate({ ...data, [name]: value });
    setError(validationErrors);
  };

  const btnsubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, password, phone, professionalMusician, instrument,confirmPassword } = data;

    const validationErrors = validate(data);
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
      return;
    }

    try {
      const payload = { firstName, lastName, email, password, phone, professionalMusician, instrument, confirmPassword };

      const response = await axios.post(`${URL}/api/signup`, payload,);

      if (response.data === "Registered Successfully") {
        console.log(response);
        setData(INITIAL_STATE);
        toast(response.data);
        navigate("/login");
      } else {
        alert(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("data",data)

  return (
    <>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <form className="signup_form">
              <h3 className="form_title text-center mb-4">
              Créer un compte
              </h3>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <div className="form_field">
                    <input
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      name="firstName"
                      value={data.firstName}
                      onChange={handleChange}
                      required
                      className="floating_input"
                    />
                    <label htmlFor="firstName" className="floating_label">
                      Prénom
                    </label>
                  </div>
                  {error.firstName && (
                    <small>{error.firstName}</small>
                  )}
                </div>
                <div className="col-sm-6 mb-3">
                  <div className="form_field">
                    <input
                      type="text"
                      id="lastName"
                      placeholder=""
                      autoComplete="off"
                      name="lastName"
                      value={data.lastName}
                      onChange={handleChange}
                      required
                      className="floating_input"
                    />
                    <label htmlFor="lastName" className="floating_label">
                      Nom de famille
                    </label>
                  </div>
                  {error.lastName && (
                    <small>{error.lastName}</small>
                  )}
                </div>
                <div className="col-sm-12 mb-3">
                  <div className="form_field">
                    <input
                      type="email"
                      id="email"
                      autoComplete="off"
                      placeholder=""
                      name="email"
                      required
                      value={data.email}
                      onChange={handleChange}
                      className="floating_input"
                    />
                    <label htmlFor="email" className="floating_label">
                      E-mail
                    </label>
                  </div>
                  {error.email && (
                    <small>{error.email}</small>
                  )}
                </div>



                <div className="col-sm-12 mb-3">
                  <div className="form_field">
                    <input
                      type="text"
                      id="phone"
                      placeholder=" "
                      name="phone"
                      autoComplete="off"
                      required
                      value={data.phone}
                      onChange={handleChange}
                      className="floating_input"
                      onKeyPress={(event) => {
                        if (!/[0-9+]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <label htmlFor="phone" className="floating_label">
                      Numéro de téléphone
                    </label>
                  </div>
                  {error.phone && (
                    <small>{error.phone}</small>
                  )}
                </div>



                <div className="col-sm-6 mb-3">
                  <div className="form_field">
                    <select
                      id="professionalMusician"
                      name="professionalMusician"
                      value={data.professionalMusician}
                      onChange={handleChange}
                      className="floating_input"
                    >
                      <option value="">Musicien professionnel ? </option>
                      <option value="OUI">OUI</option>
                      <option value="NON">NON</option>
                    </select>
                  
                  </div>
                  {error.professionalMusician && (
                    <small>{error.professionalMusician}</small>
                  )}
                </div>
                <div className="col-sm-6 mb-3">
                  <div className="form_field">
                    <select
                      id="instrument"
                      name="instrument"
                      value={data.instrument}
                      onChange={handleChange}
                      className="floating_input"
                    >
      <option value="">Instrument</option>
      {Instrument.map((el) => {
        return <option key={el} value={el}>{el}</option>;
      })}
                     
                    </select>
                  </div>  
                  {error.instrument && (
                    <small>{error.instrument}</small>
                  )}
                </div>



                <div className="col-sm-12 mb-3">
                  <div className="form_field">
                    <input
                      type={showpassword ? "text" : "password"}
                      id="password"
                      placeholder=""
                      autoComplete="off"
                      name="password"
                      value={data.password}
                      onChange={handleChange}
                       required
                      className="floating_input"
                    />
                    <label htmlFor="password" className="floating_label">
                      Mot de passe
                    </label>

                    <div
                      className="field_icon"
                      onClick={() => setShowPassword(!showpassword)}
                    >
                      {showpassword ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </div>
                  </div>
                  {error.password && (
                    <small >{error.password}</small>
                  )}
                </div>


                <div className="col-sm-12 mb-3">
                  <div className="form_field">
                    <input
                      type={showconfirmpassword ? "text" : "password"}
                      id="confirmPassword"
                      placeholder=""
                      autoComplete="off"
                      name="confirmPassword"
                      value={data.confirmPassword}
                      onChange={handleChange}
                      required
                      className="floating_input"
                    />
                    <label htmlFor="confirmPassword" className="floating_label">
                    Confirmez le mot de passe
                    </label>

                    <div
                      className="field_icon"
                      onClick={() => setShowConfirmPassword(!showconfirmpassword)}
                    >
                      {showconfirmpassword ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </div>
                  </div>
                  {error.confirmPassword && (
                    <small >{error.confirmPassword}</small>
                  )}
                </div>
                {/* <div className="text-end mb-3">
                  <span onClick={() => navigate("/login")}>Se connecter</span>
                </div> */}

                <div className="col-12">
                  <button
                    className="btn btn-primary w-100 form_btn"
                    onClick={btnsubmit}
                  >
                   Créer un compte
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
